const translation = {
  "en": {
    "bioquote": "Covid survivor",
    "h1": "About Me",
    "h2": "Hobbies, Timeline, and Random Things",
    "title": "About Me",
    "intro1": "I'm Daniel. I'm from Barcelona and I've been a programmer since 2009.",
    "lifestyle": "From a young age, I found myself immersed in a world of vintage computers. My journey into programming began with a trusty BASIC book, and later, the boundless resources of the Internet. Driven by curiosity and determination, I embarked on a self-taught path to a career in software development. While I had initially envisioned studying Software Engineering, life had other plans in store for me 😫. Undeterred, I persevered and worked relentlessly until I finally carved out my niche within the software development industry.",
    "howiam": "Why programming?",
    "howiam_reply": "The Web has always been a source of fascination for me. The sheer convenience of accessing an app with a mere keystroke of a URL, sans any installation, never ceases to amaze me. My forte lies in unraveling abstractions and crafting scalable code that stands the test of time. I also relish the opportunity to explore and experiment with emerging technologies, keeping my skills sharp and staying at the cutting edge of innovation.",
    "hobbies_t": "Hobbies",
    "hobbies": "Like many developers, I'm a bit of a geek. I used to enjoy role-playing games and strategy games. I'm still a big fan of sci-fi series, space-related content, and I'm into Japanese culture, so I watch a lot of Eastern shows. I love writing. Nowadays, I do a lot of hiking and weightlifting. Nature, nutrition, health, and fitness are all part of my life.",
    "history_title": "Timeline",
    "history_1985": "1985 - new Human()",
    "history_1990": "1990 - Got my first computer, an AMSTRAD CPC",
    "history_1993": "1993 - Video games!",
    "history_1997": "1997 - The Internet! 56kbps was all we had",
    "history_1998": "1998→2001 - Building my first websites",
    "history_2002": "2002 - Life hardships...",
    "history_2004": "2003 - Dropped out of school, got a job (money needs)",
    "history_2006": "2003→2008 - Random jobs while learning code at home",
    "history_2009": "2009 - Got a job as a Frontend Web Developer!",
    "history_2010": "2010 - Promoted to Full Stack Developer",
    "history_2013": "2013 - Promoted to Product Owner while maintaining developer responsibilities ^^",
    "history_2018": "2018 - Markets come and go, company shut down, so I decided to study 😵",
    "history_2020": "2019→2023 - 😢 Covid!! + Education + working 100% remote as a developer",
    "history_2023": "2023 - What's next?",
    "rng_title": "Questions and Answers",
    "rng_q1": "What are your favorite programming languages?",
    "rng_r1": "Well, when it comes to programming languages, I prefer statically typed and heavily object-oriented languages like Java or C#, but most of my experience has been with PHP, a dynamic one. I understand that dynamic typing is more productive for simpler tasks.",
    "rng_q2": "Do you have preferences when it comes to code editors or IDEs?",
    "rng_r2": "I'm a big fan of VIM. For simple tasks, it's my editor of choice. When I have to use heavier options like Visual Studio Code or NetBeans/IntelliJ, the first thing Ilook for is a VIM emulation plugin.",
    "rng_q3": "Mac? Linux? Windows?",
    "rng_r3": "Hmm, complex question with a lot of dependencies. I'm sure Macs are great for development, but I'll never pay that price for a laptop. Instead, I usually work with Linux on my ThinkPad. Windows 10 is very nice and becoming better for developers with the new WSL, but it really depends on what you have to do. If I have to program in Java, then NetBeans or IntelliJ in Windows is enough. If I have to work with Docker containers, I stick to Linux in a VM or dual boot."
  },
  "es": {
    "bioquote": "Superviviente del Covid",
    "h1": "Sobre mí",
    "h2": "Aficiones, cronología y cosas aleatorias",
    "title": "Sobre mí",
    "intro1": "Me llamo Daniel. Soy de Barcelona y soy programador desde 2009.",
    "lifestyle": "De pequeño, siempre estuve rodeado de ordenadores antiguos. Aprendí a programar gracias a un libro de BASIC y luego, surfeando por las infinitas olas de Internet. Comencé mi carrera a temprana edad como autodidacta. Mi sueño era estudiar Ingeniería del Software, pero a veces la vida no sigue el guion que esperamos 😫. En lugar de eso, tuve que esforzarme al máximo y trabajar duro hasta encontrar mi hueco en la emocionante industria del desarrollo de software.",
    "howiam": "¿Por qué programación?",
    "howiam_reply": "La Web siempre ha sido una fuente de fascinación para mí. La pura comodidad de acceder a una aplicación con sólo teclear una URL en el navegador, sin necesidad de instalar nada, nunca deja de sorprenderme. Mi especialidad radica en desentrañar abstracciones y diseñar código escalable que resista el paso del tiempo. También disfruto de la oportunidad de explorar y experimentar con tecnologías emergentes, manteniendo mis habilidades al día y permaneciendo a la vanguardia de la innovación.",
    "hobbies_t": "Aficiones",
    "hobbies": "Al igual que muchos desarrolladores, tengo mi lado friki. Antaño me apasionaban los juegos de rol y estrategia. A día de hoy, sigo siendo un ferviente aficionado de las series de ciencia ficción, los temas relacionados con el espacio y la enigmática cultura japonesa, lo que me lleva a disfrutar de numerosos programas orientales. La escritura siempre ha sido una de mis grandes pasiones. Actualmente, me dedico al senderismo y a levantar pesas para mantenerme en forma. La naturaleza, la nutrición, la salud y el fitness son aspectos fundamentales en mi día a día.",
    "history_title": "Cronología",
    "history_1985": "1985 - new Human()",
    "history_1990": "1990 - Recibí mi primer ordenador, un AMSTRAD CPC",
    "history_1993": "1993 - ¡Videojuegos!",
    "history_1997": "1997 - ¡Internet! 56kbps era todo lo que teníamos",
    "history_1998": "1998→2001 - Construyendo mis primeras páginas web",
    "history_2002": "2002 - Dificultades en la vida...",
    "history_2004": "2003 - Dejé la escuela, conseguí un trabajo (necesidades económicas)",
    "history_2006": "2003→2008 - Trabajos diversos mientras aprendía a programar en casa",
    "history_2009": "2009 - ¡Conseguí un trabajo como desarrollador web frontend!",
    "history_2010": "2010 - Me ascendieron a Fullstack",
    "history_2013": "2013 - Me promocionan a Responsable de Producto, pero aún puedo seguir programando",
    "history_2018": "2018 - Los mercados fluctúan, la empresa cierra y decido estudiar",
    "history_2020": "2019-2023 - Enfrentando el Covid, estudiando y trabajando como desarrollador Fullstack 100% remoto",
    "history_2023": "2023 - ¿Cuál es el siguiente paso?",
    "rng_title": "Preguntas y respuestas",
    "rng_q1": "¿Cuáles son tus lenguajes de programación favoritos?",
    "rng_r1": "Cuando se trata de lenguajes de programación, prefiero los de tipado estático y orientados a objetos, como Java o C#. Sin embargo, tengo más experiencia con lenguajes dinámicos como PHP. Entiendo que los lenguajes dinámicos son más productivos e ideales para tareas sencillas.",
    "rng_q2": "¿Tienes preferencias en cuanto al editor de código o IDE?",
    "rng_r2": "Soy un gran fan de VIM. Para tareas simples, siempre prefiero usarlo. Cuando necesito utilizar editores más pesados, como Visual Studio Code o NetBeans/IntelliJ, lo primero que busco es un plugin que emule el comportamiento de VIM.",
    "rng_q3": "¿Mac, Linux o Windows?",
    "rng_r3": "Esa es una pregunta difícil con muchos 'depende'. Sé que los Mac son excelentes para el desarrollo, pero personalmente nunca pagaré tanto por un portátil. Por lo general, prefiero trabajar con Linux en un portátil ThinkPad, que son robustos y duraderos. Windows 10 es un excelente sistema y cada vez está mejor adaptado para desarrolladores con nuevas tecnologías como WSL, pero realmente depende de lo que vayas a programar. Si tengo que trabajar en un proyecto Java, NetBeans o IntelliJ y el JDK en Windows son suficientes. Si tengo que trabajar con contenedores Docker o cualquier cosa que no sea .NET o Java, prefiero Linux."
  }
  ,
}

export default translation
